import React, { useState, useEffect, useRef } from "react";

function MathSkillModal({ onMathSkillSelect, onSkip, onClose, isFromFollowup }) {
  const [searchInput, setSearchInput] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [matchingSuggestions, setMatchingSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const inputRef = useRef(null);
  const modalRef = useRef(null);

  // Initial set of math skills for autocomplete
  const mathSkills = [
    "Addition",
    "Subtraction",
    "Multiplication",
    "Division",
    "Fractions",
    "Decimals",
    "Percentages",
    "Word Problems",
    "Basic Algebra",
    "Geometry",
    "Measurement",
    "Data Analysis",
    "Problem Solving",
    "Mental Math",
    "Number Sense"
  ];

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [matchingSuggestions]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);

    if (value.length >= 2) {
      const matches = mathSkills.filter(skill =>
        skill.toLowerCase().includes(value.toLowerCase())
      ).map(skill => ({
        text: skill,
        category: 'math_skills'
      }));

      setMatchingSuggestions(matches);
      setShowSuggestions(matches.length > 0);
    } else {
      setMatchingSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleKeyDown = (e) => {
    if (!showSuggestions || !matchingSuggestions.length) {
      if (e.key === 'Enter' && searchInput.trim()) {
        e.preventDefault();
        handleMathSkillSelect({ text: searchInput.trim(), category: 'math_skills' });
      }
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev < matchingSuggestions.length - 1 ? prev + 1 : 0
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev > 0 ? prev - 1 : matchingSuggestions.length - 1
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (matchingSuggestions[selectedIndex]) {
          handleMathSkillSelect(matchingSuggestions[selectedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        onSkip();
        break;
    }
  };

  const handleMathSkillSelect = (suggestion) => {
    onMathSkillSelect(suggestion);
    setSearchInput("");
    setShowSuggestions(false);
  };

  const handleQuickSelectSkill = (skill) => {
    handleMathSkillSelect({ text: skill, category: 'math_skills' });
  };

  return (
    <div className="modal-overlay">
      <div className="container mx-auto px-4" style={{ maxWidth: "768px" }}>
        <div className="search-container bg-white position-relative" ref={modalRef}>
          {!isFromFollowup && (
            <button
              type="button"
              className="btn-close position-absolute top-0 end-0 m-3"
              onClick={onClose}
              aria-label="Close"
            ></button>
          )}

          <h2 className="text-center mb-4">
            What math skill are you teaching?
          </h2>

          <div className="position-relative">
            <input
              ref={inputRef}
              type="text"
              className="search-input"
              placeholder="Enter a math skill..."
              value={searchInput}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              autoComplete="off"
            />

            {showSuggestions && (
              <div className="suggestions-container position-absolute w-100 mt-2 z-3">
                {matchingSuggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className={`suggestion-item ${index === selectedIndex ? 'highlighted' : ''}`}
                    onClick={() => handleMathSkillSelect(suggestion)}
                    onMouseEnter={() => setSelectedIndex(index)}
                  >
                    <i className="fa-solid fa-calculator me-2"></i>
                    {suggestion.text}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="d-flex flex-wrap gap-3 justify-content-center mt-4">
            {["Addition", "Subtraction", "Multiplication", "Division", "Word Problems"].map((skill) => (
              <button
                key={skill}
                className="filter-button"
                onClick={() => handleQuickSelectSkill(skill)}
              >
                <i className="fa-solid fa-plus"></i> {skill}
              </button>
            ))}
          </div>

          <div className="mt-4 flex justify-center">
            {isFromFollowup && (
              <button
                className="border-2 font-semibold border-green-600 text-green-600 px-8 py-1.5 mr-8 rounded-lg text-gray-700 hover:text-gray-500"
                onClick={onSkip}
              >
                Skip
              </button>
            )}
            <button
              className={`px-8 py-1.5 rounded-lg text-white ${
                !searchInput.trim()
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-emerald-500 hover:bg-emerald-600"
              }`}
              disabled={!searchInput.trim()}
              onClick={() => handleMathSkillSelect({ text: searchInput.trim(), category: 'math_skills' })}
            >
              Add Math Skill
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MathSkillModal;
