import React, { useState, useEffect, useRef } from "react";
import { flushSync } from 'react-dom';
import ContentTopicSelection from "./components/ContentTopicSelection";
import ChapterSelection from "./components/ChapterSelection";
import PDFUploadModal from "./components/PDFUploadModal";
import VideoURLModal from "./components/VideoURLModal";
import BookInputModal from "./components/BookInputModal";
import TopicInputModal from "./components/TopicInputModal";
import TextExcerptModal from "./components/TextExcerptModal";
import LinkModal from "./components/LinkModal";
import StandardsModal from "./components/StandardsModal";
import GradeSelectModal from "./components/GradeSelectModal";
import ReadingLevelSelection from "./components/ReadingLevelSelection";
import suggestions from "./data/suggestions.json";
import {
  createPacket,
  updatePacket,
  createSkillsTemplate,
  getELAStandards,
  getSocialStudiesStandards,
  getScienceStandards
} from "./services/DiffitService";
import { useSession, useUser } from "@clerk/clerk-react";
import useArtificialLogin from "./useArtificialLogin";
import {
  Bars3BottomLeftIcon,
  BookOpenIcon,
  LinkIcon,
  ShieldCheckIcon,
  ChevronRightIcon,
  DocumentIcon,
} from "@heroicons/react/24/outline";
import MathSkillModal from "./components/MathSkillModal";

const IS_DEBUG = false;
// const APP_ROOT = "https://diffit-git-pocue-ux-diffit.vercel.app";
const APP_ROOT_WSYIWYG =
  "https://diffit-git-hackathonwysiwyg-diffit.vercel.app";
const APP_ROOT_DEBUG = "http://localhost:5173";
const appRoot = IS_DEBUG ? APP_ROOT_DEBUG : APP_ROOT_WSYIWYG;

function App() {
  const [searchInput, setSearchInput] = useState("");
  const [selectedPills, setSelectedPills] = useState([]);
  const [matchingSuggestions, setMatchingSuggestions] = useState([]);
  const [showGradeSelectModal, setShowGradeSelectModal] = useState(true);
  const [gradeLevel, setGradeLevel] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  const [showSkillsIsFromFollowup, setShowSkillsIsFromFollowup] =
    useState(false);
  const [showTopicsIsFromFollowup, setShowTopicsIsFromFollowup] =
    useState(false);
  const [showContentTopicModal, setShowContentTopicModal] = useState(false);
  const [showChapterModal, setShowChapterModal] = useState(false);
  const [pendingBookSelection, setPendingBookSelection] = useState(null);
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showBookInputModal, setShowBookInputModal] = useState(false);
  const [showTopicInputModal, setShowTopicInputModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showReadingLevelModal, setShowReadingLevelModal] = useState(false);
  const [showTextExcerptModal, setShowTextExcerptModal] = useState(false);
  const [textExcerpt, setTextExcerpt] = useState("");
  const [elaStandards, setELAStandards] = useState([]);
  const [socialStudiesStandards, setSocialStudiesStandards] = useState([]);
  const [scienceStandards, setScienceStandards] = useState([]);
  const [loadingStandards, setLoadingStandards] = useState(false);
  const searchContainerRef = useRef(null);
  const inputRef = useRef(null);
  const [showMathSkillModal, setShowMathSkillModal] = useState(false);
  const [showMathSkillIsFromFollowup, setShowMathSkillIsFromFollowup] =
    useState(false);

  // Don't mind the man behind the curtain! 🧙
  const { session } = useSession();
  const { user } = useUser();
  const { clerkSessionReady } = useArtificialLogin();

  const GRADE_LEVEL_LABELS = {
    3: "3rd grade",
    4: "4th grade",
    5: "5th grade",
  };

  const loadStandards = async (state) => {
    try {
      const standards = await getELAStandards(session, state);
      setELAStandards(standards);
      setLoadingStandards(false);
    } catch (error) {
      console.error("Error getting ELA standards:", error);
    }
  };

  const loadSocialStudiesStandards = async (state) => {
    try {
      const standards = await getSocialStudiesStandards(session, state);
      setSocialStudiesStandards(standards);
      setLoadingStandards(false);
    } catch (error) {
      console.error("Error getting social studies standards:", error);
    }
  };

  const loadScienceStandards = async (state) => {
    try {
      const standards = await getScienceStandards(session, state);
      setScienceStandards(standards);
      setLoadingStandards(false);
    } catch (error) {
      console.error("Error getting science standards:", error);
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (clerkSessionReady) {
      if (selectedState) {
        loadStandards(selectedState);
        loadSocialStudiesStandards(selectedState);
        loadScienceStandards(selectedState);
      }
    }
  }, [clerkSessionReady, selectedState]);

  const hasTopicOrContent = selectedPills.some(
    (pill) =>
      pill.category === "topics" ||
      pill.category === "content" ||
      pill.category === "link" ||
      pill.category === "text",
  );
  const hasSkill = selectedPills.some(
    (pill) => pill.category === "skills" || pill.category === "standards",
  );
  const hasReadingLevel = selectedPills.some(
    (pill) => pill.category === "reading-level",
  );
  const showAdditionButtons = (hasTopicOrContent || hasSkill) && !searchInput;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    setSelectedIndex(0);
  }, [matchingSuggestions]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    const matches = filterSuggestions(value);
    setMatchingSuggestions(matches);
    setShowSuggestions(value.length >= 2 && hasActualSuggestions(matches));
  };

  const hasActualSuggestions = (matches) => {
    return matches.some(
      (match) =>
        match.type !== "separator" &&
        (!match.type || match.type === "input-echo"),
    );
  };

  const filterSuggestions = (query) => {
    if (!query || query.length < 2) return [];

    const matches = [];
    const lowercaseQuery = query.toLowerCase();

    const allItems = [
      ...(suggestions?.content?.map((item) => ({
        text: item,
        category: "content",
      })) || []),
      ...(suggestions?.topics?.map((item) => ({
        text: item,
        category: "topics",
      })) || []),
      ...(suggestions?.skills?.map((item) => ({
        text: item,
        category: "skills",
      })) || []),
    ];

    const exactMatches = allItems.filter(
      (item) => item.text.toLowerCase() === lowercaseQuery,
    );

    const startsWithMatches = allItems.filter(
      (item) =>
        item.text.toLowerCase().startsWith(lowercaseQuery) &&
        !exactMatches.some((exact) => exact.text === item.text),
    );

    const containsMatches = allItems.filter(
      (item) =>
        item.text.toLowerCase().includes(lowercaseQuery) &&
        !item.text.toLowerCase().startsWith(lowercaseQuery) &&
        !exactMatches.some((exact) => exact.text === item.text),
    );

    const allMatches = [
      ...exactMatches,
      ...startsWithMatches,
      ...containsMatches,
    ];

    matches.push({
      text: `${query}`,
      type: "input-echo",
      category: "topics",
    });

    if (allMatches.length > 0) {
      matches.push({ type: "separator" });
    }

    matches.push(...allMatches);

    return matches;
  };

  const findNextSelectableIndex = (currentIndex, direction = 1) => {
    let nextIndex = currentIndex;
    do {
      nextIndex =
        (nextIndex + direction + matchingSuggestions.length) %
        matchingSuggestions.length;
    } while (
      nextIndex !== currentIndex &&
      matchingSuggestions[nextIndex]?.type === "separator"
    );
    return nextIndex;
  };

  const removeTopicsFromInput = () => {
    console.info("removeTopicsFromInput");
  };

  const handleKeyDown = (e) => {
    if (!showSuggestions || !matchingSuggestions.length) {
      if (e.key === "Enter" && searchInput.trim()) {
        e.preventDefault();
        removeTopicsFromInput();
        addPill(searchInput.trim(), "topics");
      }
      return;
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        const nextIndex = findNextSelectableIndex(selectedIndex);
        if (matchingSuggestions[nextIndex]?.type !== "input-echo") {
          setSelectedIndex(nextIndex);
        } else {
          setSelectedIndex(findNextSelectableIndex(nextIndex));
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex(findNextSelectableIndex(selectedIndex, -1));
        break;
      case "Enter":
        e.preventDefault();
        if (
          matchingSuggestions[selectedIndex] &&
          matchingSuggestions[selectedIndex].type !== "separator"
        ) {
          handleSuggestionSelect(matchingSuggestions[selectedIndex]);
        } else if (searchInput.trim()) {
          removeTopicsFromInput();
          addPill(searchInput.trim(), "topics");
        }
        break;
      case "Escape":
        setShowSuggestions(false);
        break;
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    if (suggestion.type === "separator") {
      return;
    }

    if (suggestion.type === "input-echo") {
      addPill(suggestion.text, suggestion.category);
      return;
    }

    if (
      suggestion.category === "content" &&
      suggestions?.content?.includes(suggestion.text)
    ) {
      setPendingBookSelection(suggestion);
      setShowChapterModal(true);
    } else {
      if (suggestion.category === "topics") {
        removeTopicsFromInput();
      }
      addPill(suggestion.text, suggestion.category || "topics");
    }
    setSearchInput("");
    setShowSuggestions(false);
  };

  const handleChapterSelect = (chapterNumber) => {
    if (pendingBookSelection) {
      const bookWithChapter = {
        ...pendingBookSelection,
        text: `${pendingBookSelection.text} - Chapter ${chapterNumber}`,
        chapter: chapterNumber,
      };
      addPill(bookWithChapter.text, bookWithChapter.category);
      setPendingBookSelection(null);
      setShowChapterModal(false);
    }
  };

  const handleTopicSelect = (topicData) => {
    setShowTopicInputModal(false);
    addPill(topicData.text, topicData.category);
    if (showTopicsIsFromFollowup) {
      createResourcesAndRedirectToPrototypeOutput(topicData.text);
    }
  };

  const handleSkillSelect = (skillData) => {
    addPill(skillData.text, "skills");
    setShowSkillsModal(false);
  };

  const handleLevelSelect = (level) => {
    addPill(level, "reading-level");
    setShowReadingLevelModal(false);
  };

  const addPill = (text, category, metadata) => {
    if (
      !selectedPills.some(
        (pill) => pill.text.toLowerCase() === text.toLowerCase(),
      )
    ) {
      const newPill = { text, category, metadata };
      setSelectedPills((prev) => [...prev, newPill]);
    }
    setSearchInput("");
    setShowSuggestions(false);
  };

  const removePill = (pillToRemove) => {
    setSelectedPills(
      selectedPills.filter((pill) => pill.text !== pillToRemove.text),
    );
  };

  const skillToLanguageStandard = (skill) => {
    let output = {
      description: skill.text,
      framework: "Academic Skill",
      grade_level: "2",
      id: skill.metadata.id,
      learning_objectives: "This is from the prototype",
      name: skill.text,
      standard_set: skill.metadata.standard_set || "GLS",
    };

    if (skill.metadata?.skills) {
      output.skills_focus = skill.metadata.skills;
    }

    return output;
  };

  const transformELAStandardsToLanguageStandards = (standardsPills) => {
    const standards = standardsPills.reduce((acc, pill) => {
      const standardId = pill.metadata.id;
      if (!acc[standardId]) {
        acc[standardId] = {
          description: pill.text,
          framework: "Academic Skill",
          grade_level: "2",
          id: standardId,
          learning_objectives: "This is from the prototype",
          name: pill.text,
          standard_set: pill.metadata.standard_set || "GLS",
          skills_focus: pill.metadata.skills,
        };
      }
      return acc;
    }, {});

    let languageStandards = [];
    Object.keys(standards).forEach((standard) => {
      languageStandards.push(standards[standard]);
    });
    return languageStandards;
  };

  const getReadingLevelId = () => {
    const selectedReadingLevel = selectedPills.find(
      (pill) => pill.category === "reading-level",
    );

    const readingLevelId = selectedReadingLevel
      ? selectedReadingLevel?.text.match(/\d+/)[0]
      : gradeLevel;
    return readingLevelId;
  };

  const transformSelectedPillsToCreatePacket = (standardsPills) => {    
    let standardsELA = selectedPills.filter(
      (pill) => pill.category === "standards" && pill.metadata.type === "ela",
    );
    let standardSocialStudies = selectedPills.find(
      (pill) =>
        pill.category === "standards" &&
        pill.metadata.type === "social_studies",
    );

    // This came from follow-up
    if (standardsPills.length > 0) {
      standardsELA = standardsPills.filter(
        (pill) => pill.category === "standards" && pill.metadata.type === "ela",
      );
      standardSocialStudies = standardsPills.find(
        (pill) =>
          pill.category === "standards" &&
          pill.metadata.type === "social_studies",
      );
    }
    const mathSkill = selectedPills.find(
      (pill) => pill.category === "math_skills",
    );
    const languageStandards =
      transformELAStandardsToLanguageStandards(standardsELA);
    const userId = user?.id;
    const selectedReadingLevel = selectedPills.find(
      (pill) => pill.category === "reading-level",
    );
    const readingLevel = {
      id: getReadingLevelId(),
      name: selectedReadingLevel?.text || "6th Grade",
    };
    const language = {
      id: "1",
      name: "English",
    };

    const typeOfReading = {
      id: "1",
      name: "Informational Text",
    };

    const genre = "Nonfiction";

    let output = {
      user_id: userId,
      reading_level: readingLevel,
      language,
      type_of_reading: typeOfReading,
      languageStandards: JSON.stringify(languageStandards),
      genre,
      contentStandard: "",
    };

    let inputType = "topic";
    const selectedTopics = selectedPills.filter(
      (pill) => pill.category === "topics" || pill.category === "content",
    );
    const topicInProgress = inputRef.current.value;
    const selectedLink = selectedPills.find((pill) => pill.category === "link");
    const selectedTextExcerpt = selectedPills.find(
      (pill) => pill.category === "text",
    );

    if (mathSkill) {
      inputType = "math_story";
      output.topic = mathSkill.text;
    } else if (standardSocialStudies) {
      inputType = "topic";
      let artificialTopic = `${standardSocialStudies.metadata.id}: ${standardSocialStudies.metadata.description}`;
      if (standardSocialStudies.metadata?.optionalText) {
        artificialTopic = artificialTopic.concat(
          ` Specifically focus on ${standardSocialStudies.metadata?.optionalText}`,
        );
      }
      output.topic = artificialTopic;
    } else if (selectedTopics.length > 0 || topicInProgress) {
      inputType = "topic";
      output.topic =
        topicInProgress ||
        selectedTopics.map((selectedTopic) => selectedTopic.text).join(", ");
    } else if (selectedLink) {
      inputType = "url";
      output.url = selectedLink.text;
    } else if (selectedTextExcerpt) {
      inputType = "text";
      output.text = selectedTextExcerpt.text;
    }
    output.input_type = inputType;

    return output;
  };

  const transformResourceToUpdatePacketQueryString = (
    resource,
    readingLevelId,
  ) => {
    const queryParams = {
      resource_type: resource,
      reading_level_id: readingLevelId,
      replace: false,
      language: "English",
      vocab_list: undefined,
      question_type: undefined,
      json_existing_only: undefined,
      user_instructions: undefined,
      number: undefined,
    };

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    return queryString;
  };

  const createResources = async (session, packetId) => {
    const resources = ["summary", "vocab", "questions", "prompts", "short"];

    const updatePromises = resources.map((resource) => {
      const readingLevelId = getReadingLevelId();
      const queryString = transformResourceToUpdatePacketQueryString(
        resource,
        readingLevelId,
      );
      return updatePacket(session, packetId, queryString);
    });

    const results = await Promise.all(updatePromises);
    return results;
  };

  const LOADING_STATES = {
    creating: "Creating your resource...",
    updating: "Creating materials for your resource...",
    templates: "Creating student-ready materials for your resource...",
  };
  const [loadingState, setLoadingState] = useState(null);

  const createResourcesAndRedirectToPrototypeOutput = async (topic, standardsPills = []) => {
    console.log(
      "Generate clicked with pills:",
      selectedPills.map((pill) => pill.text),
    );
    console.info("selectedPills", selectedPills);

    setLoadingState(LOADING_STATES["creating"]);
    const createPacketPayload = transformSelectedPillsToCreatePacket(standardsPills);
    console.info("createPacketPayload", JSON.stringify(createPacketPayload));
    if (topic) {
      createPacketPayload.topic = topic;
    }
    const response = await createPacket(session, createPacketPayload);
    console.info("response", response);

    const { packet_id } = response;
    console.info("packet_id", packet_id);

    setLoadingState(LOADING_STATES["updating"]);
    const updateResults = await createResources(session, packet_id);
    setLoadingState(null);

    setLoadingState(LOADING_STATES["templates"]);
    const template = await createSkillsTemplate(session, packet_id);
    //let templateId = template?.id || "51a3dab7-7981-4500-8cab-dbe331245491";
    let templateId = template?.id;
    setLoadingState(null);
    // const redirectUrl = `${appRoot}/activity/${templateId}/${packet_id}/ue_view?_vercel_share=39eh14vM9wNrcmsY2t4Xfpgnj0HMbwRU&is_from_prototype=true`;
    // if a template is created, add it, otherwise ignore
    let redirectUrl = `${appRoot}/packet_wysiwyg/${packet_id}?_vercel_share=TQdOmIqKWJdaGnC8XeoZOPXrAdssOs9e&is_from_prototype=true`;
    if (templateId) {
      redirectUrl += `&templateId=${templateId}`;
    }
    window.location.href = redirectUrl;
  };

  const handleGenerate = async () => {
    let topicInProgress = inputRef.current.value;
    const standardSocialStudies = selectedPills.find(
      (pill) =>
        pill.category === "standards" &&
        pill.metadata.type === "social_studies",
    );
    if (!hasTopicOrContent && !topicInProgress && !standardSocialStudies) {
      setShowTopicsIsFromFollowup(true);
      setShowTopicInputModal(true);
    } else if (!hasSkill) {
      setShowSkillsIsFromFollowup(true);
      setShowSkillsModal(true);
    } else {
      await createResourcesAndRedirectToPrototypeOutput();
    }
  };

  const getSuggestionIcon = (category) => {
    switch (category) {
      case "content":
        return "fa-solid fa-book";
      case "topics":
        return "fa-solid fa-globe";
      case "skills":
        return "fa-solid fa-graduation-cap";
      default:
        return "fa-solid fa-file-text";
    }
  };

  const handleFilterClick = (type) => {
    setSearchInput("");
    setShowSuggestions(false);

    switch (type) {
      case "book":
        setShowBookInputModal(true);
        break;
      case "pdf":
        setShowPDFModal(true);
        break;
      case "topic":
        setShowTopicInputModal(true);
        break;
      case "skills":
        setShowSkillsIsFromFollowup(false);
        setShowSkillsModal(true);
        break;
      case "video":
        setShowVideoModal(true);
        break;
      case "text":
        setShowTextExcerptModal(true);
        break;
      case "link":
        setShowLinkModal(true);
        break;
      case "math":
        setShowMathSkillIsFromFollowup(false);
        setShowMathSkillModal(true);
        break;
    }
  };

  if (!clerkSessionReady || !session) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-xl">Loading authentication...</div>
      </div>
    );
  }

  if (loadingStandards) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-xl">Loading standards...</div>
      </div>
    );
  }

  const hasStandardsSelected = () => {
    return (
      selectedPills.filter((pill) => pill.category === "standards").length > 0
    );
  };

  return (
    <>
      <div className="container mx-auto px-4 mt-5" ref={searchContainerRef}>
        <h1 className="display-5 text-center">What are you teaching?</h1>

        <div className="main-content position-relative mx-auto">
          <div className="search-container">
            <div className="input-pills-container">
              <div className="pills-section">
                {selectedPills.map((pill, index) => (
                  <span
                    key={index}
                    className={`pill pill-${pill.category} ${pill.category === "text" ? "cursor-pointer bg-gray-100" : ""}`}
                    onClick={() => {
                      if (pill.category === "text") {
                        setTextExcerpt(pill.text);
                        setShowTextExcerptModal(true);
                      }
                    }}
                  >
                    {pill.category === "text" ? (
                      <>
                        <span className="font-medium pr-2 text-gray-700">
                          Pasted text
                        </span>
                        <span className="text-preview text-gray-400">
                          {pill.text.length > 30
                            ? `${pill.text.substring(0, 30)}...`
                            : pill.text}
                        </span>
                      </>
                    ) : (
                      pill.text
                    )}
                    <span
                      className={`remove ${pill.category === "text" ? "text-gray-700" : "text-white"}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        removePill(pill);
                        setTextExcerpt("");
                      }}
                    >
                      ×
                    </span>
                  </span>
                ))}
              </div>

              <div className="input-section">
                <input
                  ref={inputRef}
                  type="text"
                  className="inline-input"
                  placeholder={
                    selectedPills.length === 0
                      ? '"Gravity", "Holes, Chapter 4", "Compare & Contrast"..."'
                      : ""
                  }
                  value={searchInput}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  autoComplete="off"
                />

                {showAdditionButtons && (
                  <div className="helper-buttons">
                    {!hasTopicOrContent && (
                      <button
                        className="inline-action-button"
                        onClick={() => {
                          setShowTopicsIsFromFollowup(false);
                          setShowTopicInputModal(true);
                        }}
                      >
                        <i className="fa-solid fa-plus"></i>
                        Topic
                      </button>
                    )}
                    {!hasStandardsSelected() && (
                      <button
                        className="inline-action-button"
                        onClick={() => handleFilterClick("skills")}
                      >
                        <i className="fa-solid fa-plus"></i>
                        Skills & Standards
                      </button>
                    )}
                    {!hasReadingLevel && (
                      <button
                        className="inline-action-button"
                        onClick={() => setShowReadingLevelModal(true)}
                      >
                        <i className="fa-solid fa-plus"></i>
                        Reading Level
                      </button>
                    )}
                  </div>
                )}

                <button
                  className="generate-btn"
                  disabled={
                    (selectedPills.length === 0 && !searchInput.trim()) ||
                    !!loadingState
                  }
                  onClick={handleGenerate}
                >
                  {!loadingState ? "Generate" : "Creating..."}
                </button>
              </div>
            </div>

            {showSuggestions && (
              <div className="suggestions-container position-absolute w-100 mt-1">
                {matchingSuggestions.map((suggestion, index) => {
                  if (suggestion.type === "separator") {
                    return (
                      <div key={index} className="suggestion-separator"></div>
                    );
                  }
                  if (suggestion.type === "input-echo") {
                    return (
                      <div
                        key={index}
                        className={`suggestion-item input-echo ${
                          index === selectedIndex ? "highlighted" : ""
                        }`}
                        onClick={() => handleSuggestionSelect(suggestion)}
                        onMouseEnter={() => setSelectedIndex(index)}
                      >
                        {suggestion.text}{" "}
                        <div className="input-echo-label"> - Topic</div>
                      </div>
                    );
                  }
                  return (
                    <div
                      key={index}
                      className={`suggestion-item ${
                        index === selectedIndex ? "highlighted" : ""
                      }`}
                      onClick={() => handleSuggestionSelect(suggestion)}
                      onMouseEnter={() => setSelectedIndex(index)}
                    >
                      <i
                        className={`${getSuggestionIcon(suggestion.category)} me-2`}
                      ></i>
                      {suggestion.text}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="d-flex flex-wrap justify-content-center gap-2 mt-3">
            <button
              className="filter-button"
              onClick={() => handleFilterClick("book")}
            >
              <BookOpenIcon className="size-6 text-emerald-600" /> A Book
            </button>
            <button
              className="filter-button"
              onClick={() => handleFilterClick("pdf")}
            >
              <DocumentIcon className="size-6 text-emerald-600" /> PDF
            </button>
            <button
              className="filter-button"
              onClick={() => handleFilterClick("text")}
            >
              <Bars3BottomLeftIcon className="size-6 text-emerald-600" />
              Text Excerpt
            </button>
            <button
              className="filter-button"
              onClick={() => handleFilterClick("link")}
            >
              <LinkIcon className="size-6 text-emerald-600" /> Link
            </button>
            <button
              className="filter-button"
              onClick={() => handleFilterClick("skills")}
            >
              <ShieldCheckIcon className="size-6 text-emerald-600" /> Skills &
              Standards
            </button>
            <button
              className="filter-button"
              onClick={() => handleFilterClick("math")}
            >
              <i className="fa-solid fa-square-root-variable size-6 text-emerald-600" />
              Math Skills
            </button>
            <button className="filter-button">
              More <i className="fa-solid fa-angle-right"></i>
            </button>
          </div>
        </div>
        {loadingState && <div className="loading-state">{loadingState}</div>}
      </div>

      <footer className="footer">
        <div className="container text-center">
          <p className="mb-2">
            Diffit generates student-ready teaching materials for everything you
            teach. Learn more
            <a href="#" className="learn-more-link">
              <i className="fa-solid fa-chevron-right ms-1"></i>
            </a>
          </p>
        </div>
      </footer>

      {showGradeSelectModal && (
        <GradeSelectModal
          onGradeSelect={({ grade, state }) => {
            setGradeLevel(grade);
            setSelectedState(state);
            setShowGradeSelectModal(false);
          }}
        />
      )}

      {showTextExcerptModal && (
        <TextExcerptModal
          text={textExcerpt}
          onClose={() => setShowTextExcerptModal(false)}
          onTextSelect={({ text, category }) => {
            removeTopicsFromInput();
            addPill(text, category);
            setTextExcerpt(text);
            setShowTextExcerptModal(false);
          }}
        />
      )}

      {showLinkModal && (
        <LinkModal
          onSkip={() => setShowLinkModal(false)}
          onUrlSubmit={({ text, category }) => {
            setShowLinkModal(false);
            removeTopicsFromInput();
            addPill(text, category);
          }}
        />
      )}

      {showSkillsModal && (
        <StandardsModal
          elaStandards={elaStandards}
          socialStudiesStandards={socialStudiesStandards}
          scienceStandards={scienceStandards}
          gradeLevel={gradeLevel}
          isFromFollowup={showSkillsIsFromFollowup}
          onClose={() => setShowSkillsModal(false)}
          onSkip={() => {
            setShowSkillsModal(false);
            createResourcesAndRedirectToPrototypeOutput();
          }}
          onStandardsSelected={(selectedStandards) => {
            const topicInProgress = inputRef.current.value;
            if (topicInProgress) {
              addPill(topicInProgress, "topics");
              inputRef.current.value = "";
            }
            const category = "standards";
            let standardsPills = []
            selectedStandards.forEach((standard) => {
              const metadata = {
                id: standard.id,
                skills: standard.skills,
                standard_set: standard.standard_set,
                type: standard.type,
                description: standard.description,
                optionalText: standard.optionalText,
              };
              const getLastThreeSections = (text) => {
                const sections = text.split(".");
                return sections.slice(-3).join(".");
              };

              if (standard.type === "ela") {
                standard.skills.forEach((skill) => {
                  const shortenedId = getLastThreeSections(standard.id);
                  const standardText = `${shortenedId}: ${skill}`;
                  addPill(standardText, category, metadata);
                  standardsPills.push({
                    text: standardText,
                    category,
                    metadata
                  })
                });
              } else if (standard.type === "social_studies" || standard.type === 'science') {
                const shortenedId = getLastThreeSections(standard.id);
                const standardText = `${shortenedId}: ${standard.optionalText}`;
                addPill(standardText, category, metadata);
                standardsPills.push({
                  text: standardText,
                  category,
                  metadata
                })
              }
            });

            if (showSkillsIsFromFollowup) {
              createResourcesAndRedirectToPrototypeOutput(null, standardsPills)
            }

          }}
        />
      )}

      {showTopicInputModal && (
        <TopicInputModal
          createResourcesAndRedirectToPrototypeOutput={
            createResourcesAndRedirectToPrototypeOutput
          }
          onTopicSelect={handleTopicSelect}
          onSkip={() => {}}
          onClose={() => setShowTopicInputModal(false)}
          suggestions={suggestions}
          isFromFollowup={showTopicsIsFromFollowup}
        />
      )}

      {showChapterModal && pendingBookSelection && (
        <ChapterSelection
          bookTitle={pendingBookSelection.text}
          onChapterSelect={handleChapterSelect}
          onSkip={() => {
            setPendingBookSelection(null);
            setShowChapterModal(false);
          }}
        />
      )}

      {showPDFModal && (
        <PDFUploadModal
          onPDFSelect={handleTopicSelect}
          onSkip={() => setShowPDFModal(false)}
        />
      )}

      {showVideoModal && (
        <VideoURLModal
          onVideoSelect={handleTopicSelect}
          onSkip={() => setShowVideoModal(false)}
        />
      )}

      {showBookInputModal && (
        <BookInputModal
          onBookSelect={(bookData) => {
            setPendingBookSelection(bookData);
            setShowChapterModal(true);
            setShowBookInputModal(false);
          }}
          onSkip={() => setShowBookInputModal(false)}
        />
      )}

      {showMathSkillModal && (
        <MathSkillModal
          onMathSkillSelect={(mathSkillData) => {
            addPill(mathSkillData.text, "math_skills");
            setShowMathSkillModal(false);
            if (showMathSkillIsFromFollowup) {
              createResourcesAndRedirectToPrototypeOutput();
            }
          }}
          isFromFollowup={showMathSkillIsFromFollowup}
          onClose={() => setShowMathSkillModal(false)}
          onSkip={() => {
            setShowMathSkillModal(false);
            if (showMathSkillIsFromFollowup) {
              createResourcesAndRedirectToPrototypeOutput();
            }
          }}
        />
      )}

      {showReadingLevelModal && (
        <ReadingLevelSelection
          onLevelSelect={handleLevelSelect}
          onSkip={() => setShowReadingLevelModal(false)}
        />
      )}
    </>
  );
}

export default App;
